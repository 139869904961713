import React, { useEffect, useState } from 'react';
import axios from 'axios';
import KakaoLogin from 'react-kakao-login';
import kakaoLoginBtn from '../assets/images/kakao_login_medium_narrow.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LoginHandeler from './LoginHandeler';
import { useHistory } from 'react-router-dom';  // React Router DOM에서 useHistory import
import Button from '../components/Button';
import { toBeDisabled } from '@testing-library/jest-dom/matchers';
import { kakaoJSkey } from '../config';
import kakaoButtonImage from '../assets/images/kakao_login_medium_narrow.png';
import kakaoButtonImageWide from '../assets/images/kakao_login_large_wide.png';
import Swal from 'sweetalert2'; // SweetAlert2 임포트

const Login = () => {

    

    const history = useHistory();  // useHistory 훅 초기화
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [profileID, setProfileID] = useState('');
    const customButtonStyle = {
        backgroundColor: '#FEE500', // 카카오 노란색
        border: 'none',
        borderRadius: '5px',
        color: '#3C1E1E', // 카카오 로고 텍스트 컬러
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        const kakaoScript = document.createElement('script');
        kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        kakaoScript.async = true;
        kakaoScript.onload = () => {
            if (window.Kakao) {
                window.Kakao.init(kakaoJSkey);
                setKakaoInitialized(true); // Kakao SDK 초기화 완료
            }
        };
        document.body.appendChild(kakaoScript);

    }, [kakaoJSkey]);
    console.log(`profileID: ${profileID}`);
    

    // 로그인 성공 시 /loginloading 페이지로 이동
    const responseKakao = (response) => {
        console.log(response);
        history.push('/loginhandeler', { response: response});  
    };

    // 로그인 오류 시
    const responseFail = (err) => {
        console.error(err);
    };

    // 로그아웃 시
    const handleLogout = () => {
        console.log(`handleLogout! profileID: ${profileID}`);
        // if (kakaoInitialized && window.Kakao.Auth.getAccessToken()) {
        if (profileID) {
            window.Kakao.Auth.logout(function () {
                console.log('카카오 로그아웃 성공');
                localStorage.setItem('profileID', '');
                history.push('/');
            });
        }
    };

    


    return (
            <div style={{
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center',
                textAlign: 'center'  // 모든 요소를 가운데 정렬
            }}>
            <br/><br/><br/><br/><br/><br/><br/>
            
            {/* h2 태그 텍스트 좌측 정렬 */}
            <h2 style={{ textAlign: 'left' }}>간편하게 로그인하고 DATA101과 함께</h2>
            <h2 style={{ textAlign: 'left' }}>코딩 한 줄 없이 AI/빅데이터 분석을 시작해 보세요.</h2>

            <br/>
            <p style={{fontSize: '12px', color:'grey'}}>DATA101에 로그인하시면&nbsp;
            <a href="https://lateral-chicken-afd.notion.site/5-12e24331e1be809e8467dd135b4eac9a?pvs=4" 
                target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                 이용약관
            </a>
                과&nbsp;
            <a href="https://lateral-chicken-afd.notion.site/5-12e24331e1be8086b79ae8b727faebf1?pvs=4" 
            target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                개인정보처리방침
            </a>
                에 동의하는 것으로 간주합니다.</p>
            <br/>
            
            {/* Kakao 로그인 버튼 */}
            <div style={{ marginTop: '10px' }}> {/* 한 줄 띄워쓰기를 위한 여백 */}
                <KakaoLogin
                    token={kakaoJSkey}
                    onSuccess={responseKakao}
                    onFailure={responseFail}
                    getProfile={true}
                    useDefaultStyle={false} // 기본 스타일 사용
                    render={({ onClick }) => (
                        <img 
                            src={kakaoButtonImageWide}
                            alt="Kakao Login" 
                            onClick={onClick}
                            style={{ 
                                cursor: 'pointer', 
                                width : '40%',
                                height: 'auto',
                                display: 'block', 
                                margin: '0 auto'  // 가운데 정렬
                            }}
                        />
                    )}
                    popup={true} // 팝업 로그인 창 사용
                />
            </div>
        </div>
    
    );
};

export default Login;
