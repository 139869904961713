import React, { useState, useEffect, useRef } from 'react'
import { rdswrite } from '../config';
import axios from 'axios';

const WriteRDS = async (tableName, values) => {
    let columns = [];
    
    const columnsUserInfo = [
        "profile_id",
        "access_token",
        "id_token",
        "user_nickname",
        "user_email",
        "signup_time",
        "access_time"
    ];

    const columnsVisitInfo = [
        "uni_visit_code", 
        "page_name", 
        "page_code",
        "uid",
        "cate_uid",
        "visit_time",
        "device_type"
      ];
    
    const columnsEventInfo = [
        "uni_event_code",
        "event_code",
        "event_name",
        "transaction_no",
        "uid",
        "cate_uid",
        "device_type",
        "event_time",
        "page_name",
        "page_code",
    ];


    if (tableName === 'tb_user_info') {
        columns = columnsUserInfo;
    } else if (tableName === 'tb_visit_info'){
        columns = columnsVisitInfo;
    } else if (tableName === 'tb_event_info') {
        columns = columnsEventInfo;
    }


    try {
        console.log(`WriteRDS 실행 / tableName : ${tableName}, columns : ${columns}`);

        //RDS
        const response = await axios.post(rdswrite, {
            table_name: tableName,
            columns: columns,
            values: [Object.values(values)],
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            },

        ).then((res) => {
            console.log(`데이터 적재 완료 : ${res.data.body}`)
        });

    } catch (error) {
        console.log(`error: ${error}`);
    }
};

export default WriteRDS