const category = [
    {
        display: "Áo thun",
        categorySlug: "ao-thun"
    },
    {
        display: "Áo somi",
        categorySlug: "ao-somi"
    },
    {
        display: "Quần jean",
        categorySlug: "quan-jean"
    }
]

export default category