import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import { addItem } from '../redux/shopping-cart/cartItemsSlide';
import { remove } from '../redux/product-modal/productModalSlice';

import Button from './Button';
import numberWithCommas from '../utils/numberWithCommas';
import Swal from 'sweetalert2';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

import loadingDefault from '../assets/images/loading_default.gif';
import '../sass/FileUpload.css'
import visImgSample from '../assets/images/vis_example_240707.png';
import errorMsgImg from '../assets/images/error_msg_img_resize.png'

import FileSelectBtn from './FileUpload';
import { mainColor, UploadFileAPI, visualizeKeywordFreqAPI, visualizeNetworkAPI } from '../config';
import InfoTooltip from './InfoTooltip';

import WriteRDS from './WriteRDS';
import EventTracker from './EventTracker';

const ProductViewVisualizer = (props) => {
    const disabledBtnColor = 'grey'
    const dispatch = useDispatch();

    let pageName="visualization";
    let pageCode="p0006";
    let eventCode="";
    let eventName="";
    let values = [];
    const tableNameEventInfo = 'tb_event_info';

    let product = props.product || {
        title: "",
        price: '',
        image01: null,
        image02: null,
        categorySlug: "",
        colors: [],
        slug: "",
        size: [],
        description: "",
        descriptionTag: "",
        dataCrawlingType: [],
        urlInputGuideText: "",
        urlInputGuideTextSub: "",
    };

    const [previewImg, setPreviewImg] = useState(product.imageDetail);
    const [descriptionExpand, setDescriptionExpand] = useState(false);
    const [color, setColor] = useState(undefined);
    const [size, setSize] = useState(undefined);
    const [quantity, setQuantity] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadBtnDisabled, setFileUploadBtnDisabled] = useState(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);
    const [uploadFileHeaders, setUploadFileHeaders] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedDateColumn, setSelectedDateColumn] = useState('');
    const [fileValidation, setFileValidation] = useState(false);

    //----------------------파일/시각화 버튼 컬러----------------------//
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    //------------------------------------------------------------//

    //-------------------output img 할당변수-------------------//
    const [imgWordCloud, setImgWordCloud] = useState(null);
    const [imgKeywordFreq, setImgKeywordFreq] = useState(null);
    const [imgWordNetwrok, setImgWordNetwork] = useState(null);
    //-------------------------------------------------------//

    const [visualizeKeywordFreqIsClicked, setVisualizeKeywordFreqIsClicked] = useState(false);
    const [visualizeNetworkIsClicked, setVisualizeNetworkIsClicked] = useState(false);

    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const [loadingVisKeywordFreq, setLoadingVisKeywordFreq] = useState(false);
    const [loadingVisNetwork, setLoadingVisNetwork] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화

    const [transactionNo, setTransactionNo] = useState("-");
    const [productCode, setProductCode] = useState('-');
    const [serviceCode, setServiceCode] = useState('-');
    const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));

    //====================준비 중인 서비스 클릭 시 동작====================
    // const currentUrl = window.location.href;
    // if (currentUrl.includes('vis-others')) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: '🛠️서비스 준비 중',
    //         html: "8월 중 오픈 예정입니다 :)",
    //         confirmButtonText: '확인',
    //     });
    //     history.push('/');
    // }
    //==============================================================

    useEffect(() => {
        setPreviewImg(product.imageDetail);
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        if (loadingFileUpload) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 5000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;

                if (elapsed >= maxWaitTime && (!imgKeywordFreq || !imgWordCloud | !imgWordNetwrok)) {
                    setLoadingFileUpload(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }

    }, [product, loadingFileUpload, loadingVisNetwork]);
    console.log(`profileID: ${profileID}`);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([1, 2, 3, 4, 5]);

    const checkboxTextData = [
        { id: 1, text: '리뷰 내용' },
        { id: 2, text: '리뷰 평점' },
        { id: 3, text: '리뷰 등록일' },
        { id: 4, text: '작성자명' },
        { id: 5, text: '좋아요 개수' },
    ];

    const optionPrices = {
        option1: 10000,
        option2: 15000,
        option3: 20000,
        option4: 25000,
        option5: 30000,
    };

    const [selectedOption, setSelectedOption] = useState('option1');
    const [estimatedCost, setEstimatedCost] = useState(optionPrices.option1);

    const showWaitPopup = () => {
        Swal.fire({
            icon: 'info',
            title: '📊시각화를 시작했어요',
            html: '최대 30초까지 소요돼요. 잠시만 기다려주세요.<br/>새로고침하시면 파일 업로드부터 다시 하셔야 돼요.',
            timer: 30000,
            allowOutsideClick: false, // 팝업 밖을 클릭해도 닫히지 않음
            allowEscapeKey: false,    // ESC 키로 닫을 수 없게 설정
            allowEnterKey: false,     // 엔터 키로 닫을 수 없게 설정
            timerProgressBar: true,   // 타이머 진행 상황 표시
            didOpen: () => {
                Swal.showLoading();     // 팝업에 로딩 애니메이션 표시
            },
        });

        if (imgKeywordFreq && imgWordCloud && imgWordNetwrok) {
            Swal.close();  // 조건이 만족되면 팝업 닫기
        }
      
    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue && optionPrices[selectedValue] !== undefined) {
            const cost = optionPrices[selectedValue];
            setEstimatedCost(cost);
        } else {
            setEstimatedCost(0);
        }
    };

    ///////////////// 불용어 등록 ///////////////////
    const [queue1, setQueue1] = useState([]);
    const [queue2, setQueue2] = useState([]);
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');

    const handleChange1 = (e) => {
        setInput1(e.target.value);
    };
    const handleKeyPress1 = (e) => {
        eventName = "stopword_add";
        eventCode = "e0020";
        trackEvent();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        if (e.key === 'Enter' && input1.trim() !== '') {
            setQueue1([input1, ...queue1]);
            setInput1('');
        }
    };
    const handleRemove1 = (index) => {
        eventName = "stopword_remove";
        eventCode = "e0021";
        trackEvent();
        setQueue1(queue1.filter((_, i) => i !== index));
    };
    const handleChange2 = (e) => {
        setInput2(e.target.value);
    };
    const handleKeyPress2 = (e) => {
        eventName = "propernoun_add";
        eventCode = "e0022";
        trackEvent();
        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        if (e.key === 'Enter' && input2.trim() !== '') {
            setQueue2([input2, ...queue2]);
            setInput2('');
        }
    };
    const handleRemove2 = (index) => {
        eventName = "propernoun_remove";
        eventCode = "e0023";
        trackEvent();
        setQueue2(queue2.filter((_, i) => i !== index));
    };
    ////////////////////////////////////////////////////


    const uploadFileS3 = async () => {

        try {
            setLoadingFileUpload(true);
            setImgKeywordFreq(null);
            setImgWordCloud(null);
            setImgWordNetwork(null);

            Swal.fire({
                title: '💨파일 업로드 중',
                html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            eventName = "upload_file";
            eventCode = "e0014";
            trackEvent();

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "데이터 시각화는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                });
                history.push('/login');
            }

            // 파일 미선택 시 경고 팝업
            if (!fileUploadedSuccessfully) {
                Swal.fire({
                    icon: 'warning',
                    title: 'csv 파일을 업로드해 주세요',
                    html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;

            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {

                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    }
                    else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = await axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        console.log('File uploaded successfully:', res.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `이제 분석할 컬럼을 선택해 주세요`,
                            confirmButtonText: '확인',
                            didOpen: () => {
                                Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                            }
                        });
                        setDataUploadFlag(true);
                        setLoadingFileUpload(false);
                    });

                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '👀파일 상태를 확인해 주세요',
                        html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                        confirmButtonText: '확인',
                        didOpen: () => {
                            Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                        }
                    });
                    setDataUploadFlag(false);
                    setLoadingFileUpload(false);
                    setData([]);
                    setSelectedFile("");
                    setFileValidation(false);
                    setFileUploadedSuccessfully(false);
                }

            }
        } catch (error) {
            console.log(`파일 업로드 중 에러 발생 : ${error}`);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });
            setDataUploadFlag(false)
            setLoadingFileUpload(false);
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            setData([]);
            setSelectedFile("");
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (data.length === 0) return [];
        const columnWidths = data[0].map((_, colIndex) => {
            const maxLength = Math.max(
                ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
            );
            return maxLength * 9; // Adjust the multiplier as needed
        });
        return columnWidths;
    };

    const dataPreviewSetup = (fileExtension) => {
        try{
            const reader = new FileReader();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');
                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 위치 수정 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1

                        });
                        setData(jsonData.slice(0, 10));

                    }
                };
                if (fileExtension === 'csv') {
                    console.log("fileExtension === 'csv'");
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                    reader.readAsBinaryString(selectedFile);
                }

            }}
        catch (error){
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });

        }

    }

    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        if (loadingFileUpload) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '400px',
                        border: '0.5px solid grey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        fontSize: '1.5em',
                        borderRadius: '8px', // 둥근 모서리 추가
                        padding: '3px', // 내부 여백 추가
                        color: 'grey',
                    }}
                >

                    <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
                    <p>파일 업로드는 최대 30초까지 걸려요 :)</p>
                </div>
            )
        }

        else if (!loadingFileUpload && dataUploadFlag) {
            return (
                <div>
                    <br />
                    <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                        <thead>
                            <tr>
                                {data[0] &&
                                    data[0].map((header, index) => (
                                        <th key={index} style={{ width: columnWidths[index] + 20, border: '1px solid black', padding: '8px' }}>
                                            {header}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={{ width: columnWidths[cellIndex] + 20, border: '1px solid black', padding: '8px' }}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        else if (!loadingFileUpload) {
            return (<div
                style={{
                    width: '100%',
                    height: '200px',
                    border: '0.5px solid grey',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    fontSize: '1.5em',
                    borderRadius: '10px', // 둥근 모서리 추가
                    padding: '3px', // 내부 여백 추가
                    color: 'grey',
                }}
            >
                파일 업로드 시 일부 Data가 미리 보여집니다.
            </div>)
        }
    };

    const showErrorImg = () => {
        if (errorFlag && !imgKeywordFreq && !imgWordCloud && !imgWordNetwrok) {
            return (
                <img src={errorMsgImg} style={{ height: '50%', border: '0.5px solid grey' }}>
                </img>
            )
        }
    }

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    // 시계열 데이터
    const handleDateColumnChange = (event) => {
        setSelectedDateColumn(event.target.value);
    };

    // 분석할 컬럼 선택 통합
    const selectColumnTotal = () => {
        return dataUploadFlag ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                    <select value={selectedColumn} onChange={handleColumnChange}
                        style={{
                            padding: '10px', fontSize: '14px', borderRadius: '12px',
                            color: mainColor, cursor: 'pointer', border: `0.5px solid grey`,
                            transition: 'background-color 0.3s ease, color 0.3s ease'
                        }}>
                        <option value="" disabled>❗️[필수] 텍스트 분석할 열 선택 </option>
                        {data[0].map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <InfoTooltip text="❗️[필수] 텍스트가 포함된 열을 선택해 주세요" />
                </div>
                {/* <div style={{display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                    <select value={selectedDateColumn} onChange={handleDateColumnChange}
                            style={{padding: '10px', fontSize: '14px', borderRadius : '12px',
                            cursor: 'pointer', border: `0.5px solid grey`,
                            transition: 'background-color 0.3s ease, color 0.3s ease'} }>
                        <option value="">[선택] 날짜정보(datetime) 열 선택 </option>
                        {data[0].map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <InfoTooltip text="날짜 관련 열을 선택하시면 시계열 분석 차트를 제공해 드려요" />
                </div> */}
            </div>
        )

            :
            (
                null
            )
    }

    // selectedFile.name
    const saveVisImg = (visType) => {
        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        // 이미지가 하나라도 로드 안 되었을 경우 경고 팝업
        if (!imgKeywordFreq || !imgWordCloud || !imgWordNetwrok) {
            Swal.fire({
                icon: 'error',
                title: '❗️시각화 미완료',
                html: `파일 업로드👉분석할 컬럼 선택👉시각화 버튼 클릭 후<br/>이미지가 완전히 출력된 후 다운로드 버튼을 클릭해 주세요`,
                confirmButtonText: '확인',
            });
            return;
        }

        let imageDataUrl = "";
        let filename = "";
        const filenameWithoutExtension = selectedFile.name.replace('.csv', '');
        if (visType === 'wc') { 
            imageDataUrl = imgWordCloud; 
            filename = `data101_${filenameWithoutExtension}.png`;
            eventName = "download_vis_wc";
            eventCode = "e0004";
            trackEvent();
        }
        else if (visType === 'wf') { 
            imageDataUrl = imgKeywordFreq; 
            filename = `data101_${filenameWithoutExtension}_word_frequency.png`;
            eventName = "download_vis_wf";
            eventCode = "e0005";
            trackEvent();
        }
        else if (visType === 'wn') { 
            imageDataUrl = imgWordNetwrok; 
            filename = `data101_${filenameWithoutExtension}_word_network.png`; 
            eventName = "download_vis_wn";
            eventCode = "e0006";
            trackEvent();
        }

        try {
            // Base64 데이터 부분 추출
            const base64Data = imageDataUrl.split(',')[1];
            const mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0];

            // Base64 데이터를 Blob으로 변환
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeString });

            // Blob을 Object URL로 변환
            const url = URL.createObjectURL(blob);

            // 다운로드 링크 생성 및 클릭 이벤트 트리거
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Object URL 해제
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error during image download:', error);
        }
    }

    const showImgDownBtn = (visType) => {
        return (
            <div style={{ paddingLeft: '15px' }}><Button
                size="sm"
                // disabled={!loadingComplete}
                backgroundColor={(!loadingComplete) ? 'grey' : mainColor}
                onClick={() => saveVisImg(visType)}
            >다운로드</Button></div>
        )
    }

    const showKeywordFreq = () => {
        return (
            <div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                        style={{
                            width: '100%',
                            height: '10px',
                            display: 'flex',
                        }}
                    >
                        <h2>☁️ 워드클라우드 시각화</h2>
                        {showImgDownBtn('wc')}

                    </div>
                    <br />
                    <div
                        style={{
                            width: '100%',
                            height: '10px',
                            display: 'flex'
                        }}
                    >
                        <h2>📊 키워드 빈도 차트 시각화</h2>
                        {showImgDownBtn('wf')}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '10px',
                            display: 'flex'
                        }}
                    >
                        <h2>🕸️ 키워드 연결망 시각화</h2>
                        {showImgDownBtn('wn')}
                    </div>
                </div>
                <br />
                <br />
                <div style={{ display: 'flex', gap: '10px' }}>
                    {handleVisualizeKeywordFreq()}
                </div>
            </div>
        )
    }

    // 통합 시각화 버튼 추가 | 24-06-29
    const visualizeTotal = () => {
        eventName = "visualize";
        eventCode = "e0015";
        trackEvent();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        const reader = new FileReader();
        const fileExtension = selectedFile.name.split('.').pop();

        if (fileExtension === 'csv') {
            reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
        }

        reader.onload = (e) => {
            const fileData = e.target.result;

            Papa.parse(fileData, {
                header: true,
                complete: (results) => {
                    const csvData = results.data; // CSV 파일의 데이터

                    // 첫 번째 행을 헤더로 사용한 후 두 번째 행 탐색
                    if (csvData.length > 1) {
                        // const secondRow = csvData[0]; // 두 번째 행 (index 1)
                        // let validDateFound = false;

                        // // 날짜 형식 정규식
                        // const datePattern1 = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD 형식
                        // const datePattern2 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/; // YYYY-MM-DD HH:MM:SS+09:00 형식

                        // // 두 번째 행의 데이터를 20열까지 탐색
                        // const keys = Object.keys(secondRow); // 헤더 정보
                        // for (let i = 0; i < Math.min(20, keys.length); i++) {
                        //     const cellValue = secondRow[keys[i]]; // 각 셀 값

                        //     // 셀 값이 존재하는지 확인
                        //     if (cellValue) {
                        //         console.log(`셀 값: ${cellValue}`); // 디버깅용 로그

                        //         // 날짜 형식인지 확인
                        //         if (datePattern1.test(cellValue) || datePattern2.test(cellValue)) {
                        //             validDateFound = true;
                        //             console.log(`유효한 날짜 발견: ${cellValue}`); // 디버깅용 로그
                        //             break; // 유효한 날짜가 있으면 중단
                        //         } else {
                        //             console.log(`유효하지 않은 날짜 형식: ${cellValue}`); // 디버깅용 로그
                        //         }
                        //     } else {
                        //         console.log(`비어있는 셀: ${keys[i]}`); // 디버깅용 로그
                        //     }
                        // }

                        // // 유효한 날짜가 없으면 경고창 표시
                        // if (!validDateFound) {
                        //     Swal.fire({
                        //         icon: 'warning',
                        //         title: '날짜 형식을 맞춰주세요',
                        //         html: 'YYYY-MM-DD 형식 또는<br/>YYYY-MM-DD HH:MM:SS+09:00 형식 (ISO 8601)',
                        //         confirmButtonText: '확인',
                        //     });
                        //     return;
                        // }
                        if (selectedColumn) {
                            setUnixTime(Math.floor(Date.now() / 1000));
                            onVisualizeKeywordFreq();
                            onVisualizeNetwork();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: '❗️분석할 컬럼 선택 필요',
                                html: "파일 업로드와 분석하실 컬럼을 먼저 선택해 주세요",
                                confirmButtonText: '확인',
                            });
                            return
                        }
                    }
                }
            });
        };

    }

    // 키워드 빈도 차트 시각화 버튼 클릭 시
    const onVisualizeKeywordFreq = () => {
        setLoadingVisKeywordFreq(true);
        console.log(`키워드 빈도 차트 시각화 버튼 클릭!`);
        setVisualizeKeywordFreqIsClicked(true);
        console.log(`"profileID" : ${profileID},
                    "filename"   : ${selectedFile.name},
                    "selectedColumn" : ${selectedColumn}`)
        try {
            // 분석용 컬럼, profileID, 파일명 전송    
            const req = {
                "profileID": profileID,
                "filename": selectedFile.name,
                "selectedColumn": selectedColumn,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "stopword": queue1,
                "proper_noun": queue2,
                "unix_time": unixTime,
            }
            console.log('Request Data:', req); // 요청 데이터 로그 출력

            const response = axios.post(visualizeKeywordFreqAPI, req,
                {
                    headers: {
                        'Content-Type': 'application/json', // JSON 데이터로 전송
                    },
                })
                .then((res) => {
                    console.log(`res : ${res.data}`)
                    const { resImgWordCloud, resImgWordFreq } = res.data;
                    setImgWordCloud(`data:image/jpeg;base64,${resImgWordCloud}`);
                    setImgKeywordFreq(`data:image/jpeg;base64,${resImgWordFreq}`);
                    setLoadingVisKeywordFreq(false);
                    setErrorFlag(false);
                })


        } catch (error) {
            console.log(`키워드 빈도 차트 시각화 시 에러 발생 : ${error}`);
            // 요청 실패 시 로딩 상태 변경
            setLoadingVisKeywordFreq(false);
            setErrorFlag(true);
            Swal.fire({
                icon: 'warning',
                title: '데이터 분석 중 문제가 생겼어요',
                html: '분석할 컬럼이 올바르게 선택되었는지<br/>확인 후 분석을 다시 시작해 주세요.',
                confirmButtonText: '확인',
            });
        }
    };

    // 네트워크 분석 시각화 결과 받아오기
    const onVisualizeNetwork = () => {
        setLoadingVisNetwork(true);
        setVisualizeNetworkIsClicked(true);
        console.log(`네트워크 시각화 버튼 클릭!`);
        console.log(`"profileID" : ${profileID},
                    "filename"   : ${selectedFile.name},
                    "selectedColumn" : ${selectedColumn}`)

        try {
            // 분석용 컬럼, profileID, 파일명 전송    
            const req = {
                "profileID": profileID,
                "filename": selectedFile.name,
                "selectedColumn": selectedColumn,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "stopword": queue1,
                "proper_noun": queue2,
                "unix_time": unixTime,
            }
            console.log(`unix_time : ${unixTime}`);

            const response = axios.post(visualizeNetworkAPI, req,
                {
                    headers: {
                        'Content-Type': 'application/json', // JSON 데이터로 전송
                    },
                })
                .then((res) => {
                    console.log(`res : ${res.data}`)
                    const { resImgWordNetwork } = res.data;
                    setImgWordNetwork(`data:image/jpeg;base64,${resImgWordNetwork}`);
                    setLoadingVisNetwork(false);
                    setErrorFlag(false);
                })


        } catch (error) {
            console.log(`네트워크 시각화 시 에러 발생 : ${error}`);
            setLoadingVisNetwork(false);
            setErrorFlag(true);
            Swal.fire({
                icon: 'warning',
                title: '데이터 분석 중 문제가 생겼어요',
                html: '분석할 컬럼이 올바르게 선택되었는지<br/>확인 후 분석을 다시 시작해 주세요.',
                confirmButtonText: '확인',
            });
        }
    };

    const handleVisualizeKeywordFreq = () => {

        if (!visualizeKeywordFreqIsClicked) {
            return (
                <img src={visImgSample}></img>
            )
        }

        if (loadingVisKeywordFreq || loadingVisNetwork) {
            showWaitPopup();
            // return (
            //     <div
            //         style={{
            //             width: '100%',
            //             height: '400px',
            //             border: '1px solid black',
            //             display: 'flex',
            //             justifyContent: 'center',
            //             alignItems: 'center',
            //             margin: '0 auto',
            //             fontSize: '1.5em',
            //             borderRadius: '10px', // 둥근 모서리 추가
            //             padding: '3px' // 내부 여백 추가
            //         }}
            //     >

            //         <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
            //         <p>시각화를 시작했어요! 최대 30초까지 걸려요 :)</p>
            //     </div>
            // )
        } else if (imgKeywordFreq && imgWordCloud && imgWordNetwrok) {
            Swal.fire({
                icon: 'success',
                title: '📊 시각화 완료!',
                html: "다운로드 버튼을 누르면 이미지를 받으실 수 있어요",
                confirmButtonText: '확인',
                showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                  }
            });
            return (
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    marginTop: '20px'
                }}>
                    <img
                        src={imgWordCloud}
                        style={{
                            width: 'calc(100% / 3 - 10px)', // 가로 길이의 1/3 설정, 간격 고려하여 조정
                        }}
                        alt="Fetched from Lambda"
                    />
                    <img
                        src={imgKeywordFreq}
                        style={{
                            width: 'calc(100% / 3 - 10px)', // 가로 길이의 1/3 설정, 간격 고려하여 조정
                        }}
                        alt="Fetched from Lambda"
                    />
                    <img
                        src={imgWordNetwrok}
                        style={{
                            width: 'calc(100% / 3 - 10px)', // 가로 길이의 1/3 설정, 간격 고려하여 조정
                        }}
                        alt="Fetched from Lambda"
                    />
                </div>
            )
        };
    }


    return (
        <div>
            <div>
            <img src={previewImg} alt="" style={{ width: '100%', height: 'auto' }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                <h2>🗂️ 분석할 Data 업로드하기</h2>
                <InfoTooltip text="현재 2.5MB 이하의 CSV 파일만 분석 가능해요" />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                <div className='file-upload'>
                    <input type="file" id="ex_file" onChange={onFileChange}
                        style={{ display: 'none' }} /> {/* 기본 파일 업로드 input 숨김 */}
                    <div className="button-container" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        {selectedFile && (
                            <h3>
                                <strong>📍 업로드된 파일 : </strong> {selectedFile.name} ({selectedFile.size} bytes)
                            </h3>
                        )}
                        <FileSelectBtn></FileSelectBtn>
                        <Button size="sm"
                            onClick={uploadFileS3}>업로드하기</Button>
                    </div>
                </div>
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h2>🔍 분석할 선택하기</h2>
                {selectColumnTotal()}
                <div>
                    <Button
                        size="sm"
                        // disabled={!selectedColumn}
                        backgroundColor={mainColor}
                        onClick={visualizeTotal}
                    >
                        시각화 시작하기
                    </Button>
                </div>
            </div>
            <br />
            {dataPreviewShow()}
            <br />
            <br /><br />
            <div className='preprocess-grid-container'>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                    <h2>✅ (선택) 제거할 단어 추가하기 </h2>
                    <InfoTooltip text="불필요한 단어를 제거할 수 있어요(ex.안녕, ㅋㅋㅋ, 감사)" />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                    <h2>✅ (선택) 고유명사 등록하기</h2>
                    <InfoTooltip text="사람이름, 브랜드명 등을 온전히 표시하기 위해 필요해요" />
                </div>
            </div>
            <div className='preprocess-grid-container'>
                <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                        <h3>👇 시각화 시 제거될 단어 리스트</h3>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            {queue1.map((item, index) => (
                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                    <span>{item}</span>
                                    <button onClick={() => handleRemove1(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <input type="text" value={input1} onChange={handleChange1} onKeyPress={handleKeyPress1}
                        placeholder="단어 입력 후 Enter 누르시면 리스트에 단어가 추가돼요" style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
                </div>


                <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                        <h3>👇 시각화 시 고려될 고유명사 리스트</h3>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            {queue2.map((item, index) => (
                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                    <span>{item}</span>
                                    <button onClick={() => handleRemove2(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <input type="text" value={input2} onChange={handleChange2} onKeyPress={handleKeyPress2}
                        placeholder="단어 입력 후 Enter 누르시면 리스트에 단어가 추가돼요"
                        style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
                </div>
            </div>
            <br />
            <br />
            <br />
            <div>{showKeywordFreq()}</div>
            <div>{showErrorImg()}</div>
        </div>
    );
};

ProductViewVisualizer.propTypes = {
    product: PropTypes.object,
};

export default withRouter(ProductViewVisualizer);