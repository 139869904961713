import React from 'react'
import Swal from 'sweetalert2';
import ci from '../assets/images/uf5-logo-no-bg.png';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import WriteRDS from './WriteRDS';
import EventTracker from './EventTracker';
import { SiKakaotalk } from 'react-icons/si';

const iconStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: 'black',
    margin: '0.1px',
    cursor: 'pointer', // 커서 스타일 추가
};

const iconInnerStyle = {
    color: 'white',
    fontSize: '26px',
};

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between', // 좌측 텍스트와 우측 아이콘 간격 확보
    alignItems: 'center',
    height: '25vh',
};

const textStyle = {
    fontSize: '15px',
    textAlign: 'left',
};

const buttonContainerStyle = {
    display: 'flex',
    gap: '10px',
    marginTop: '10px'
};

const inforPopUpStyle = {
    fontSize: '12px',
    color: 'grey',
    backgroundColor: 'black',
    textDecoration: 'underline'
};

const Footer = () => {
    const pageName = "footer";
    const pageCode = "p0011";
    const transactionNo = "-";
    let eventName = "";
    let eventCode = "";
    const tableName = 'tb_event_info';
    let values = [];

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableName, values);
    };

    // const openSwal = (title, content) => {
    //     Swal.fire({
    //         title: title,
    //         html: `<p style="text-align:left;">${content}</p>`,
    //         icon: 'info',
    //         confirmButtonText: '닫기',
    //         customClass: {
    //             popup: 'swal2-popup',
    //         }
    //     });
    // };

    const openInstagram = () => {
        eventName = "insta_btn";
        eventCode = "e0017";
        trackEvent();
        window.open('https://www.instagram.com/data101.official/',
            '_blank', 'noopener,noreferrer');
    };

    const openYoutube = () => {
        eventName = "youtubue_btn";
        eventCode = "e0024";
        trackEvent();
        window.open('https://www.youtube.com/@data101.official',
            '_blank', 'noopener,noreferrer');
    };

    const openKatalk = () => {
        eventName = "katalk_channel_btn";
        eventCode = "e0026";
        trackEvent();
        window.open('http://pf.kakao.com/_SxltHG',
            '_blank', 'noopener,noreferrer');
    };


    return (
        <footer className="footer">
            <div className="container" style={containerStyle}>
                {/* 좌측 텍스트 영역 */}
                <div className="footer__about" style={textStyle}>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <img src={ci} alt="" style={{ width: '4%' }} />
                        <h3>주식회사 유에프5</h3>
                    </div>
                    <br />
                    <p style={{ color: 'grey' }}>
                        대표이사 : 박경빈 | 이메일 : gbpark@uf5.io | 전화번호 : 0507-1334-3546 <br />
                        사업자등록번호: 376-87-03088 | 통신판매신고번호 : 제2024-서울강남-03158호<br />
                        서울특별시 강남구 남부순환로 359길 14, 3층<br />
                        <div style={buttonContainerStyle}>
                            <a style={inforPopUpStyle} href="https://lateral-chicken-afd.notion.site/5-12e24331e1be809e8467dd135b4eac9a?pvs=4" target="_blank">서비스 이용약관</a>
                            <a style={inforPopUpStyle} href="https://lateral-chicken-afd.notion.site/5-12e24331e1be8086b79ae8b727faebf1?pvs=4" target="_blank">개인정보 취급방침</a>
                        </div>
                        <br />
                        Copyright © 2024 UF5. All Rights Reserved.<br />
                    </p>
                </div>

                {/* 우측 아이콘 영역 */}
                <div style={{ display: 'flex', gap: '0.01px' }}>
                    <div style={iconStyle}>
                        <FaInstagram style={iconInnerStyle} onClick={openInstagram} role="button" aria-label="Instagram" />
                    </div>
                    <div style={iconStyle}>
                        <FaYoutube style={iconInnerStyle} onClick={openYoutube} role="button" aria-label="Youtube" />
                    </div>
                    <div style={iconStyle}>
                        <SiKakaotalk style={iconInnerStyle} onClick={openKatalk} role="button" aria-label="katalk" />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
