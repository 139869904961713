import React, { useState } from 'react';
import './InfoTooltip.css';

const InfoTooltip = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div 
            className="tooltip-container"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div className="tooltip-circle">?</div>
            {showTooltip && <div className="tooltip-text">{text}</div>}
        </div>
    );
};

export default InfoTooltip;