import React, { useEffect, useState } from 'react';
import axios from 'axios';
import KakaoLogin from 'react-kakao-login';
import kakaoLoginBtn from '../assets/images/kakao_login_medium_narrow.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LoginHandeler from './LoginHandeler';
import { useHistory } from 'react-router-dom';  // React Router DOM에서 useHistory import
import Button from '../components/Button';
import { toBeDisabled } from '@testing-library/jest-dom/matchers';
import { kakaoJSkey } from '../config';
import kakaoButtonImage from '../assets/images/kakao_login_medium_narrow.png';
import kakaoButtonImageWide from '../assets/images/kakao_login_large_wide.png';
import Swal from 'sweetalert2'; // SweetAlert2 임포트
import ImgBetatest from '../assets/images/b2c_betatest_survey.png';
import WriteRDS from '../components/WriteRDS';
import EventTracker from '../components/EventTracker';
import { useLocation } from 'react-router-dom';

const Betatest = () => {
    const location = useLocation();
    const [source, setSource] = useState('');
    let pageName="betatest";
    let pageCode="p00012";
    let eventCode="";
    let eventName="";
    let values = [];
    let transactionNo = "-";
    const tableNameEventInfo = 'tb_event_info';


    const history = useHistory();  // useHistory 훅 초기화
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [profileID, setProfileID] = useState('');
    const customButtonStyle = {
        backgroundColor: '#FEE500', // 카카오 노란색
        border: 'none',
        borderRadius: '5px',
        color: '#3C1E1E', // 카카오 로고 텍스트 컬러
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        const kakaoScript = document.createElement('script');
        kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        kakaoScript.async = true;
        kakaoScript.onload = () => {
            if (window.Kakao) {
                window.Kakao.init(kakaoJSkey);
                setKakaoInitialized(true); // Kakao SDK 초기화 완료
            }
        };
        document.body.appendChild(kakaoScript);

        // 현재 URL에서 쿼리 파라미터 추출
        const urlParams = new URLSearchParams(window.location.search);
        const params = new URLSearchParams(location.search);
        const sourceParamHistroy = params.get('source');

        console.log(`urlParams : ${urlParams} / sourceParamHistroy : ${sourceParamHistroy}`);
        const sourceParam = urlParams.get('source');
        console.log(`source : ${sourceParam}`);
        setSource(sourceParam || sourceParamHistroy || ''); // source 값이 없으면 빈 문자열로 설정

    }, [kakaoJSkey]);
    console.log(`profileID: ${profileID}`);


    // 로그인 성공 시 /loginloading 페이지로 이동
    const responseKakao = (response) => {
        console.log(response);
        history.push('/loginhandelerbetatest', { response: response });
    };

    // 로그인 오류 시
    const responseFail = (err) => {
        console.error(err);
    };

    // 로그아웃 시
    const handleLogout = () => {
        console.log(`handleLogout! profileID: ${profileID}`);
        // if (kakaoInitialized && window.Kakao.Auth.getAccessToken()) {
        if (profileID) {
            window.Kakao.Auth.logout(function () {
                console.log('카카오 로그아웃 성공');
                localStorage.setItem('profileID', '');
                history.push('/');
            });
        }
    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const clickSurvey = () => {
        eventName="betatest_survey_btn";
        eventCode="e0025";
        trackEvent();
        window.open(`https://walla.my/survey/lDhzRi6CBpFUhkSjIJ0P?source=${source}&userID=${profileID}`, "_blank");
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'  // 모든 요소를 가운데 정렬
        }}>
            <img
                src={ImgBetatest}
                alt="ImgBetatest"
                style={{
                    width : '60%',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto'  // 가운데 정렬
                }}
            />
            <br/><br/>
            {profileID
            ? 
            <div>
                <h2 style={{ textAlign: 'center' }}>최소 1개 이상의 서비스 경험 후 설문에 참여해 주세요 :)</h2>
                <br/>
                <Button onClick={clickSurvey}
                >설문참여하기
                </Button>
            </div>
            : 
            <div>
                <h2 style={{ textAlign: 'center' }}>간편하게 로그인하고 설문에 참여해 주세요 :)</h2>
                <br/>
                <div style={{ marginTop: '10px' }}> {/* 한 줄 띄워쓰기를 위한 여백 */}
                    <KakaoLogin
                        token={kakaoJSkey}
                        onSuccess={responseKakao}
                        onFailure={responseFail}
                        getProfile={true}
                        useDefaultStyle={false} // 기본 스타일 사용
                        render={({ onClick }) => (
                            <img
                                src={kakaoButtonImageWide}
                                alt="Kakao Login"
                                onClick={onClick}
                                style={{
                                    cursor: 'pointer',
                                    width: '40%',
                                    height: 'auto',
                                    display: 'block',
                                    margin: '0 auto'  // 가운데 정렬
                                }}
                            />
                        )}
                        popup={true} // 팝업 로그인 창 사용
                    />
                </div>
            </div>
            }
            
        </div>

    );
};

export default Betatest;
