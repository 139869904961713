export const getCurrentDateTime = () => {
  const now = new Date();
  
  // 한국 시간을 기준으로 UTC Offset을 계산합니다 (KST: UTC+9)
  const utcOffset = 9 * 60; // 9 hours * 60 minutes

  // UTC Offset을 반영하여 한국 시간으로 변환합니다
  now.setMinutes(now.getMinutes() + utcOffset);

  // ISO 8601 형식으로 포맷팅합니다
  const formattedDateTime = now.toISOString().slice(0, 23);

  return formattedDateTime;
};