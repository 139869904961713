import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { kakaoJSkey, KakaoLoginAPI } from '../config';
import EventTracker from '../components/EventTracker';
import loadingDefault from '../assets/images/loading_default.gif';

import WriteRDS from '../components/WriteRDS';
import showCurentTime from '../components/showCurrentTime';

const LoginHandlerBetatest = (props) => {
    const location = useLocation();
    const response = location.state?.response;

    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [profileID, setProfileID] = useState('');

    let pageName="sign_login";
    let pageCode="p0002";
    let eventCode="";
    let eventName="";
    let values = [];
    let transactionNo = "-";
    const tableNameEventInfo = 'tb_event_info';

    useEffect(() => {
        if (response) {
            const postResponseData = async () => {
                try {

                    const postResponse = await axios.post(KakaoLoginAPI, response, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    const receivedProfileID = postResponse.data.message;
                    setProfileID(receivedProfileID);
                    console.log(`postResponse.data.message : ${receivedProfileID}`);
                    console.log('POST 성공:', receivedProfileID);

                    const koTime = showCurentTime()
                    // RDS 데이터 적재
                    const tableName = 'tb_user_info';

                    const values = {
                        profile_id: response.profile.id,
                        access_token: response.response.access_token,
                        id_token: response.response.id_token,
                        user_nickname: response.profile.properties.nickname,
                        user_email: response.profile.kakao_account.email,
                        signup_time: response.profile.connected_at,
                        access_time: koTime,
                    };

                    Object.entries(values).forEach(([key, value]) => {
                        console.log(`Key: ${key}, Value: ${value}`);
                    });

                    // WriteRDS 함수 호출
                    await WriteRDS(tableName, values);
                    if (response.profile.id){
                        eventName="sign_login";
                        eventCode="e0001";
                        trackEvent();
                    }

                    history.push('/betatest?source=ryq1Z');
                    



                } catch (error) {
                    console.error('POST 요청 중 오류 발생:', error);
                }
            };

            postResponseData();
        }

        const kakaoScript = document.createElement('script');
        kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        kakaoScript.async = true;

        kakaoScript.onload = () => {
            if (window.Kakao) {
                window.Kakao.init(kakaoJSkey);
                setKakaoInitialized(true);
            }
        };
        document.body.appendChild(kakaoScript);
    }, [response]);

    useEffect(() => {
        if (profileID) {
            localStorage.setItem('profileID', profileID);
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }, [profileID, history]);


    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const handleLogout = () => {
        if (kakaoInitialized && window.Kakao.Auth.getAccessToken()) {
            window.Kakao.Auth.logout(() => {
                console.log('카카오 로그아웃 성공');
                history.push('/');
            });
        }
    };

    return (
        <div>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <h2>로그인 중입니다 :)</h2>
                    <img style={{ height: '50%' }} src={loadingDefault} alt="loading" />
                    <p>🙏새로고침하지 마시고 잠시만 기다려 주세요</p>
                </div>
            ) : (
                <div>
                    <button onClick={handleLogout}>카카오 로그아웃</button>
                    <h1>로그인 성공!</h1>
                </div>
            )}
        </div>
    );
};

export default LoginHandlerBetatest;
