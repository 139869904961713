import React, { useContext, useEffect, useState } from 'react';
// import { tableVisitInfo, saveDataToDynamoDB } from '../config';
import { rdswrite } from '../config';
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
import axios from 'axios';

import WriteRDS from './WriteRDS';
import showCurentTime from './showCurrentTime';

const PageTracker = ({ pageName, pageCode }) => {

  useEffect(() => {
    if (pageName) {
      const postResponseData = async () => {
        try {

          //사용자ID
          const storedProfileID = localStorage.getItem('profileID');

          // 방문시간
          const visitTime = showCurentTime();

          // 디바이스 타입 
          let deviceType = "";
          if (isMobile()) deviceType = 'mw';
          else if (isDesktop()) deviceType = 'web';

          // 방문값 누적 적재용 (PK)
          const uniVisitCode = storedProfileID + "_" + pageCode + "_" + visitTime;
          const cateUid = "profile";

          console.log(`uniVisitCode: ${uniVisitCode}`);

          // DB 저장용 배열 세팅
          const values = {
            uni_visit_code : uniVisitCode,
            page_name : pageName,
            page_code: pageCode,
            uid: storedProfileID,
            cate_uid: cateUid,
            visit_time: visitTime,
            device_type: deviceType,
          }

          console.log("uniVisitCode:", values.uni_visit_code);
          console.log("page_name:", values.page_name);
          console.log("page_code:", values.page_code);
          console.log("uid:", values.uid);
          console.log("cate_uid:", values.cate_uid);
          console.log("visit_time:", values.visit_time);
          console.log("device_type:", values.device_type);
          const tableName = 'tb_visit_info';

          await WriteRDS(tableName, values);
        }
      catch (error) {
        console.log(`error : ${error}`)
      }
    }
    postResponseData();
  }}, [pageName]);

  return null;
};

export default PageTracker;