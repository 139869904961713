import React, {useEffect} from 'react'
import Swal from 'sweetalert2';
import { useHistory, Route, Switch, useLocation } from 'react-router-dom'

import Home from '../pages/Home'
import Catalog from '../pages/Catalog'
import Product from '../pages/Product'
// 로그인 페이지 라우트 추가 | 2024-05-19 | gbpark
import Login from '../pages/Login'
import LoginHandeler from '../pages/LoginHandeler'
import LoginHandelerBetatest from '../pages/LoginHandelerBetatest'
import MyPage from '../pages/Mypage'
import PaySuccess from '../pages/PaySuccess'
import PayFail from '../pages/PayFail'
import PageTracker from '../components/PageTracker';
import Betatest from '../pages/Betatest';

const Routes = () => {
    const history = useHistory();  // useHistory 훅 사용
    const location = useLocation(); // useLocation 훅 사용

    useEffect(() => {
        // 페이지 이동 시 스크롤을 최상단으로 설정
        window.scrollTo(0, 0);
    }, [location.pathname, location.key]); // location.pathname이 변경될 때마다 호출


    return (
        <Switch>
            <Route exact path='/'> 
                <PageTracker pageName="home"
                             pageCode="p0001" />
                <Home />
            </Route>
            
            <Route path='/catalog/:slug' render={(props) => {
                const { slug } = props.match.params;
                let pageName = slug;
                let pageCode = "";
                console.log(`페이지이동 : slug / ${slug}`);

                if      (slug.includes('youtube')) pageCode="p0004";
                else if (slug.includes('google'))  pageCode="p0005";
                else if (slug.includes('visualization'))  pageCode="p0006";
                else if (slug.includes('ai-algorithm')) pageCode="p0007";

                // else if (slug.includes('ai-algorithm')) {
                //     pageCode="p0007";
                //     Swal.fire({
                //         icon: 'error',
                //         title: '현재 서비스 개발 중',
                //         html: "'24년 10월 중 오픈 예정입니다.",
                //         confirmButtonText: '확인',
                //     }).then(() => {
                //         history.replace('/');  // history.replace를 사용해 '/'로 리다이렉트
                //     });
                //     return null;  // 컴포넌트 렌더링하지 않음
                // }
                
                return (
                    <React.Fragment>
                        <PageTracker pageName={slug} 
                                     pageCode={pageCode} />
                        <Product {...props} />
                    </React.Fragment>
                );
            }} />

            <Route exact path='/login'> 
                <PageTracker pageName="login"
                             pageCode="p0002" />
                <Login />
            </Route>

            <Route exact path='/mypage'> 
                <PageTracker pageName="mypage" 
                             pageCode="p0003"/>
                <MyPage />
            </Route>

            <Route exact path='/paysuccess'> 
                <PageTracker pageName="paysuccess"
                             pageCode="p008" />
                <PaySuccess />
            </Route>

            <Route exact path='/payfail'> 
                <PageTracker pageName="payfail"
                             pageCode="p0009" />
                <PayFail />
            </Route>

            <Route exact path='/betatest'> 
                <PageTracker pageName="betatest"
                             pageCode="p0012" />
                <Betatest />
            </Route>

            <Route exact path='/catalog' component={Catalog}/>
            <Route exact path="/loginHandeler" component={LoginHandeler}/>
            <Route exact path="/loginHandelerbetatest" component={LoginHandelerBetatest}/>
            
        </Switch>
    )
}

export default Routes
