const size = [
    {
        display: "S",
        size: "s"
    },
    {
        display: "M",
        size: "m"
    },
    {
        display: "L",
        size: "l"
    },
    {
        display: "XL",
        size: "xl"
    },
    {
        display: "XXL",
        size: "xxl"
    },
]

export default size