const showCurentTime = () => {
    const now = new Date();
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000); // UTC 시간으로 변환
    const koreaTime = new Date(utcTime + (9 * 60 * 60 * 1000)); // KST(+9)로 변환

    // 대한민국 시간으로 "YYYY-MM-DD HH:MM:SS" 형식 만들기
    const koTime = `${koreaTime.getFullYear()}-${String(koreaTime.getMonth() + 1).padStart(2, '0')}-${String(koreaTime.getDate()).padStart(2, '0')} ` +
        `${String(koreaTime.getHours()).padStart(2, '0')}:${String(koreaTime.getMinutes()).padStart(2, '0')}:${String(koreaTime.getSeconds()).padStart(2, '0')}`;

    return koTime;
}

export default showCurentTime;