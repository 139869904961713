import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { PutCommand } from '@aws-sdk/lib-dynamodb';
import AWS from 'aws-sdk'; // AWS SDK
import axios from 'axios';

// API 접근해서 Lambda 돌려서 DB 접근 / Lambda 돌려서 S3 접근 하기 
const API_PROXY_HANDLER = 'https://ul4yz1p2wl.execute-api.ap-northeast-2.amazonaws.com/dev/dev';

// AWS 환경 변수
let KakaoLoginAPI = '';
let rdsread = '';
let rdswrite = '';
let scraperGooglePlayStoreReviewLight = '';
let scraperGooglePlayStoreReviewMain = '';
let scraperYoutubeCommentLight = '';
let scraperYoutubeCommentMain = '';
let UploadFileAPI = '';
let visualizeKeywordFreqAPI = '';
let visualizeNetworkAPI = '';
let REGION = '';
let kakaoJSkey = '';
let tossClientKeClientLive = '';
let tossClientKeySecretLive = '';
let API_S3_GETOBJ1 = '';
let sentimentClassifierAPI = '';
let intentClassifierAPI = '';
let gptinsightAPI = '';

const fetchEnvironmentVariables = async () => {
  try {
    const response = await axios.post(API_PROXY_HANDLER);
    // 응답 데이터를 JSON 객체로 파싱
    const data = JSON.parse(response.data.body);
    const {
      REACT_APP_API_KAKAO_LOGIN,
      REACT_APP_API_RDS_READ,
      REACT_APP_API_RDS_WRITE,
      REACT_APP_API_SCRAPER_GOOGLE_PLAYSTORE_LIGHT,
      REACT_APP_API_SCRAPER_GOOGLE_PLAYSTORE_MAIN,
      REACT_APP_API_SCRAPER_YOTUBE_COMMENT_LIGHT,
      REACT_APP_API_SCRAPER_YOTUBE_COMMENT_MAIN,
      REACT_APP_API_UPLOADFILE,
      REACT_APP_API_VISUALIZE_KEYWORD_FREQ,
      REACT_APP_API_VISUALIZE_KEYWORD_NETWORK,
      REACT_APP_AWS_REGION,
      REACT_APP_KAKAO_JS_KEY,
      REACT_APP_TOSS_CLIENT_KEY_LIVE,
      REACT_APP_TOSS_CLIENT_KEY_SECRET_LIVE,
      REACT_APP_API_S3_GETOBJ1,
      REACT_APP_API_SENTIMENT_CLASSIFIER,
      REACT_APP_API_INTENT_CLASSIFIER,
      REACT_APP_API_GPT_INSIGHT
    } = data;

    KakaoLoginAPI = REACT_APP_API_KAKAO_LOGIN;
    rdsread = REACT_APP_API_RDS_READ;
    rdswrite = REACT_APP_API_RDS_WRITE;
    scraperGooglePlayStoreReviewLight = REACT_APP_API_SCRAPER_GOOGLE_PLAYSTORE_LIGHT;
    scraperGooglePlayStoreReviewMain = REACT_APP_API_SCRAPER_GOOGLE_PLAYSTORE_MAIN;
    scraperYoutubeCommentLight = REACT_APP_API_SCRAPER_YOTUBE_COMMENT_LIGHT;
    scraperYoutubeCommentMain = REACT_APP_API_SCRAPER_YOTUBE_COMMENT_MAIN;
    UploadFileAPI = REACT_APP_API_UPLOADFILE;
    visualizeKeywordFreqAPI = REACT_APP_API_VISUALIZE_KEYWORD_FREQ;
    visualizeNetworkAPI = REACT_APP_API_VISUALIZE_KEYWORD_NETWORK;
    REGION = REACT_APP_AWS_REGION;
    kakaoJSkey = REACT_APP_KAKAO_JS_KEY;
    tossClientKeClientLive = REACT_APP_TOSS_CLIENT_KEY_LIVE;
    tossClientKeySecretLive = REACT_APP_TOSS_CLIENT_KEY_SECRET_LIVE;
    API_S3_GETOBJ1=REACT_APP_API_S3_GETOBJ1;
    sentimentClassifierAPI=REACT_APP_API_SENTIMENT_CLASSIFIER;
    intentClassifierAPI=REACT_APP_API_INTENT_CLASSIFIER;
    gptinsightAPI=REACT_APP_API_GPT_INSIGHT;

    // console.log('googleplaystorelight :', { scraperGooglePlayStoreReviewLight });

    console.log('Environment variables fetched:', { REGION });
    // console.log('key:', {ACCESS_KEY_ID});

    // AWS SDK 초기화
    AWS.config.update({
      region: REGION,
    });

    console.log('AWS SDK configuration updated:', AWS.config);


  } catch (error) {
    console.error('Error fetching environment variables:', error);
  }
};

// 초기 호출을 통해 환경 변수 설정
const initialize = fetchEnvironmentVariables().then(() => {
  console.log('Environment variables fetched and AWS SDK initialized');
});

// 외부에서 사용할 수 있도록 export
export {
  KakaoLoginAPI,
  rdsread,
  rdswrite,
  scraperGooglePlayStoreReviewLight,
  scraperGooglePlayStoreReviewMain,
  scraperYoutubeCommentLight,
  scraperYoutubeCommentMain,
  UploadFileAPI,
  visualizeKeywordFreqAPI,
  visualizeNetworkAPI,
  REGION,
  kakaoJSkey,
  tossClientKeClientLive,
  tossClientKeySecretLive,
  API_S3_GETOBJ1,
  sentimentClassifierAPI,
  intentClassifierAPI,
  gptinsightAPI
};

// export const mainColor = 'rgb(77, 127, 170)';
export const mainColor = 'rgb(63, 100, 243)';