import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import KakaoLogin from 'react-kakao-login';
import Button from '../components/Button'
import { ScanCommand } from '@aws-sdk/lib-dynamodb';

import Papa from 'papaparse'; // 엑셀 파싱 모듈
import AWS from 'aws-sdk'; // aws 전용 
import { saveAs } from 'file-saver'; // 파일 저장 모듈
import EventTracker from '../components/EventTracker';
import ProgressBarScrap from '../components/ProgressBarScrap'; // 크롤링 진척 확인용
import Swal from 'sweetalert2';

// import { dynamodb } from '../config';
import { rdsread, API_S3_GETOBJ1 } from '../config';
import InfoTooltip from '../components/InfoTooltip'; // 데이터 다운로드 받을 수 있는 곳 안내용

import WriteRDS from '../components/WriteRDS';

const mainColor = 'rgb(70, 100, 166)';
const Mypage = () => {

    let pageName="mypage";
    let pageCode="p0003";
    let eventCode="";
    let eventName="";
    let values = [];
    let transactionNo = "-";
    const tableNameEventInfo = 'tb_event_info';

    // let filteredData;
    // 퍼널 분석용 페이지명

    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const { Kakao } = window;
    const [logoutTracked, setLogoutTracked] = useState(false);

    const [dataCollectList, setDataCollectList] = useState([]);
    const [visualizeFile, setVisualizeFile] = useState([]);
    const [unixTime, setUnixTime] = useState('');

    const dataCollectHeader = [
        "수집/분석 요청일", // request_date
        // "고객번호", // 삭제예정
        // "거래번호", // transaction_no
        "수집/분석채널ID", // service_code (유튜브 or 플레이스토어)
        "데이터수집or분석파일명",
        "건수", // request_cnt
        "처리상태", // complete_code (0 : 진행중,  1 : 완료)
        "링크",    // 유튜브 or 플레이스토어 원형에 맞게
        "결과받기" // 다운로드 버튼용
    ]

    const [dataCollectHeaderWidths, setDataCollectHeaderWidths] = useState([]);
    const [paymentListHeaderWidths, setPaymentListHeaderWidths] = useState([]);

    const [dataCollected, setDataCollected] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    // const s3 = new AWS.S3();

    const paymentHeader = [
        "결제일(paymentDate)",  // payment_date
        // "고객번호", // 삭제예정
        "주문번호(transactionNo)", // transaction_no
        "상품종류", // service_code / 크롤링 Or 시각화
        "수집채널",  // 구글플레이스토어 Or 유튜브
        "수집건수", // request_cnt
        "결제금액", // payment_amount
        "링크" // 링크
    ];

    const logoutEventParams = {
        pageName: "logout",
        pageCode: "p0002",
        eventName: "logout",
        eventCode: "e0002",
        transactionNo: "",
    };

    const getMaxTextWidth = (texts) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let maxWidth = 0;

        texts.forEach((text) => {
            const width = context.measureText(text).width;
            if (width > maxWidth) {
                maxWidth = width + 20;
            }
        });

        return maxWidth;
    };

    const showTableList = ['tb_user_request', 'tb_payment'];

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        if (!storedProfileID & !logoutTracked) history.push('/login');
        console.log(`현재 프로필 ID(로컬스토리지에서 가져온) : ${storedProfileID}`)

        // 데이터 수집 이력 테이블 헤더 컬럼 길이 세팅
        const dataCollectedWidths_ = dataCollectHeader.map(header => getMaxTextWidth([header]));
        setDataCollectHeaderWidths(dataCollectedWidths_);

        const paymentListWidths_ = paymentHeader.map(header => getMaxTextWidth([header]));
        setPaymentListHeaderWidths(paymentListWidths_);

        // showTableList.map((tableName) => {
        //     fetchUserData(tableName);
        // })
    }, [logoutTracked]);

    useEffect(() => {
        if (profileID) {
            console.log(`현재 프로필 ID: ${profileID}`);
            showTableList.forEach((tableName) => {
                fetchUserData(tableName);
            });
        }
    }, [profileID]); // profileID가 변경될 때마다 호출

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };


    // 쿼리해서 해당 사용자만 가져오기.
    const fetchUserData = async (tableName) => {
        try {
            // Lambda 함수 호출
            console.log(`profileID_Mypage : ${profileID}`);

            //RDS
            const response = await axios.post(rdsread, {
                table_name: tableName,
                ProfileID: profileID  // 쿼리에서 사용할 ProfileID
            });

            // 응답에서 데이터 추출
            const responseData = response.data.body;
            let data;
            if (typeof responseData === 'string') {
                data = JSON.parse(responseData);
            } else {
                data = responseData;
            }
            console.log(`data : ${data}`);
            console.log(`tableName : ${tableName}`);

            // 테이블 이름에 따라 데이터를 상태에 저장
            if (tableName === showTableList[0]) {
                setDataCollected(data);
            } else if (tableName === showTableList[1]) {
                setPaymentList(data);
            }

            console.log(`${tableName} 데이터 가져오기 성공:`, data);
        } catch (error) {
            console.error(`${tableName} 데이터 가져오기 오류:`, error);
        }
    };

    // 로그아웃 버튼 클릭 시 호출 함수
    const handleLogout = () => {
        eventName = "logout";
        eventCode = "e0002";
        trackEvent();
        console.log('handleLogout 실행');

        if (Kakao.Auth.getAccessToken()) {
            console.log(
                '카카오 인증 액세스 토큰이 존재합니다.',
                Kakao.Auth.getAccessToken(),
            );

            Kakao.Auth.logout(() => {
                console.log('로그아웃 되었습니다.', Kakao.Auth.getAccessToken());
                
                setProfileID('');
                localStorage.clear();
                setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                history.push('/'); // 로그아웃 후에 페이지 이동
            });
        } else {
            console.log('카카오 인증 액세스 토큰이 존재하지 않습니다.');
            setProfileID('');
            localStorage.clear();
            setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
            history.push('/'); // 액세스 토큰이 없을 때 페이지 이동
        }

        Swal.fire({
            icon: 'success',
            title: '로그아웃되었습니다',
            confirmButtonText: '확인',
        });
    };

    // 회원탈퇴 버튼 클릭 시 호출 함수
    const handleDeleteAccount = () => {
        eventName = "resignation";
        eventCode = "e0003";
        trackEvent();
        Swal.fire({
            title: '회원탈퇴 하시겠습니까?',
            html: `서비스 이용내역은 탈퇴 신청일로부터 1년간 보관되며<br/>탈퇴 이후에도 언제든 재가입하실 수 있습니다.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '탈퇴하기',
            cancelButtonText: '취소하기'
        }).then((result) => {
            if (result.isConfirmed) {
                // 사용자가 '탈퇴' 버튼을 클릭한 경우에만 회원탈퇴 진행
                if (Kakao.Auth.getAccessToken()) {
                    console.log(
                        '카카오 인증 액세스 토큰이 존재합니다.',
                        Kakao.Auth.getAccessToken(),
                    );

                    Kakao.API.request({
                        url: '/v1/user/unlink',
                        success: function (response) {
                            console.log('회원탈퇴(연결 끊기) 성공', response);
                            Swal.fire({
                                icon: 'success',
                                title: '회원탈퇴가 완료되었습니다',
                                confirmButtonText: '확인',
                            });
                            setProfileID('');
                            localStorage.clear();
                            setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                            history.push('/'); // 탈퇴 후에 페이지 이동
                        },
                        fail: function (error) {
                            console.error('회원탈퇴(연결 끊기) 실패', error);
                            Swal.fire({
                                icon: 'error',
                                title: '회원탈퇴에 실패했습니다',
                                text: '다시 시도해주세요.',
                                confirmButtonText: '확인',
                            });
                        },
                    });
                } else {
                    console.log('카카오 인증 액세스 토큰이 존재하지 않습니다.');
                    setProfileID('');
                    localStorage.clear();
                    setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                    history.push('/'); // 액세스 토큰이 없을 때 페이지 이동
                }
            }
        });
    };



    const dataCollectedTextReplace = (text, column, channel) => {

        if (column === 'service_code') {
            if (text === 'cr_0001') return '구글 플레이 스토어 리뷰 수집';
            else if (text === 'cr_0002') return '유튜브 댓글 수집';
            else if (text.includes('an_')) return '데이터 시각화';
            else if (text.includes('ai_')) return 'AI 데이터 분류';

        } else if (column === 'complete_code') {
            if (text === 0) return '📀 진행중';
            else if (text === 1) return '✅ 완료';
            else return text;

        } else if (column == '링크') {
            if (channel === 'cr_0001') return `https://play.google.com/store/apps/details?id=${text}`;
            else if (channel === 'cr_0002') return `https://www.youtube.com/watch?v=${text}`;
            else if (channel.includes('an')) return '-';

        } else if (column == '상품종류') {
            if (text.includes('cr')) return "Data 수집";
            else if (text.includes('an')) return "Data 분석";

        } else {
            return;
        }

    };

    const formatDate = (inputDateString) => {
        const date = new Date(inputDateString);
        // console.log('date',{inputDateString});

        // 월과 일이 한 자리 수일 경우 앞에 0을 붙이기 위한 함수
        const padZero = (num) => (num < 10 ? '0' + num : num);

        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1);  // getMonth()는 0부터 시작하므로 +1
        const day = padZero(date.getDate());
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());

        return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
    };

    //Filesaver -> link 형태로 변경
    //s3.object 에서 lambda 접근으로 변경
    const downloadFile = async (profileID, complete_code, product_name, serviceCode, productCode, request_cnt, unixTime) => {
        try {

            eventName = "download_mypage";
            eventCode = "e0009";
            trackEvent();

            if (complete_code == 0){
                Swal.fire({
                    icon: 'warning',
                    title: '🎈잠시 후 다시 시도해 주세요',
                    html: serviceCode.includes('cr') 
                    ? `크롤링은 최대 15분까지 걸릴 수 있어요.<br/>계속 문제가 해결되지 않으면 카톡 문의해 주세요 :)`
                    : `처리상태가 완료된 후 다시 다운로드 해주세요.<br/>계속 문제가 해결되지 않으면 카톡 문의해 주세요 :)`,
                    confirmButtonText: '확인',
                });
                return;
            }

            console.log(`download 버튼 클릭`);
            console.log(`unixTime : ${unixTime}`);
            const req = {
                "profileid"   : profileID,
                "serviceCode" : serviceCode,
                "productCode" : productCode,
                "request_cnt" : request_cnt,
                "fileName"    : product_name,
                "unix_time"    : unixTime,
            };

            const response = await axios.post(API_S3_GETOBJ1, req, {
                responseType: 'json'  // 서버에서 JSON 응답을 받기
            });
            
            console.log(`serviceCode: ${serviceCode}`);
            console.log(`product_name: ${product_name}`);

            
            if (serviceCode.includes('an') || serviceCode.includes('ai')) {
                if (response.status === 200) {
                    console.log('서버 응답 성공:', response.data);
        
                    // response.data.body가 JSON 문자열이므로 한 번 더 파싱
                    let fileData;
                    try {
                        fileData = JSON.parse(response.data.body);  // JSON 문자열을 객체로 변환
                        console.log('fileData 파싱 성공:', fileData);
                    } catch (e) {
                        console.error('JSON 파싱 중 오류 발생:', e);
                        return;
                    }
        
                    // fileData가 배열인지 확인 후 처리
                    if (Array.isArray(fileData)) {
                        console.log('fileData 배열 확인:', fileData);
                        Swal.fire({
                            icon: 'success',
                            title: '💽 다운로드 성공!',
                            html: serviceCode.includes('an') ? '시각화 결과 전체를 다운로드했어요' : 'AI 텍스트 분류 결과 전체를 다운로드했어요',
                            confirmButtonText: '확인',
                        });
        
                        fileData.forEach(file => {
                            // file.content가 존재하는지 확인
                            if (!file.content) {
                                console.error(`file.content가 존재하지 않음: ${file.fileName}`);
                                return;
                            }
        
                            try {
                                // 1. 이미지 파일 처리
                                if (file.fileName.includes('.jpg')){
                                    // Base64 문자열에서 잘못된 문자를 필터링
                                    console.log(`jpg 파일 다운로드 시도 / ${file.fileName}`);
                                    const cleanedBase64 = file.content.replace(/[^A-Za-z0-9+/=]/g, '');
                                    
                                    const byteCharacters = atob(cleanedBase64); // Base64 디코딩
                                    const byteNumbers = new Array(byteCharacters.length);
                                    for (let i = 0; i < byteCharacters.length; i++) {
                                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                                    }
                                    const byteArray = new Uint8Array(byteNumbers);
                                    const blob = new Blob([byteArray], { type: 'image/jpeg' });
            
                                    // Blob URL을 생성하고 다운로드 처리
                                    const link = document.createElement('a');
                                    const url = window.URL.createObjectURL(blob);
                                    link.href = url;
                                    link.download = file.fileName;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link); // 다운로드 후 링크 제거
                                    window.URL.revokeObjectURL(url); // Blob URL 해제
                                }

                                // 2. csv 파일 처리
                                else if (file.fileName.includes('.csv')) {
                                    console.log(`csv 파일 다운로드 시도 / ${file.fileName}`);
                                    const decodedFileContent = file.content;
                                    const blobCSV = new Blob([decodedFileContent], { type: 'text/csv;charset=utf-8;' });
                                    const linkCSV = document.createElement('a');
                                    const urlCSV = URL.createObjectURL(blobCSV);
                                    linkCSV.href = urlCSV;
                                    linkCSV.setAttribute('download', file.fileName);  // 다운로드할 파일명 설정
                                    document.body.appendChild(linkCSV);
                                    linkCSV.click();
                                    document.body.removeChild(linkCSV);
                                }

                            } catch (error) {
                                console.error(`파일 다운로드 중 오류 발생: ${file.fileName}`, error);
                            }
                        });
                    } else {
                        console.error('fileData는 배열이 아님:', fileData);
                    }
                } else {
                    console.error('응답이 성공적이지 않음:', response.status);
                }
        
            }


            // 크롤링 파일 경우
            else {
                // 서버 응답이 오류 메시지인지 확인
                if (response.headers['content-type'].includes('application/json')) {
                    console.error('오류 응답:', response.data);
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: '💽 다운로드 성공!',
                    html: '크롤링한 데이터를 다운로드했어요',
                    confirmButtonText: '확인',
                });

                const { body, isBase64Encoded } = response.data;

                // base64 문자열을 UTF-8로 디코딩
                const fileContent = isBase64Encoded ? atob(body) : body;

                // 파일 콘텐츠로 Blob 생성 (UTF-8 with BOM)
                const blob = new Blob([fileContent], { type: 'text/csv; charset=utf-8' });
                const downloadFilename = `${serviceCode}_${productCode}_${request_cnt}.csv`;

                // 파일 다운로드 처리
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', downloadFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); // 메모리 해제
            }



        } catch (error) {
            console.error(`파일 다운로드 오류: ${error}`);
        }
    };


    const handleLink = (item) => {
        console.log(`item : ${item}`);
        return (
            item.service_code.includes("cr")
                ? <a href={dataCollectedTextReplace(item.product_code, "링크", item.service_code)} onClick={clickLink} target='_blank' style={{ color: 'blue', textDecoration: 'underline' }}>링크</a>
                : <p>-</p>
        )

    }

    const clickLink = () => {
        eventName="mypage_link";
        eventCode="e0010";
        trackEvent();
    }





    //Data 수집/분석 이력 = showDataCollectList = TableRow + DataTable
    const TableRow = ({ item, downloadFile }) => {
        const [progress, setProgress] = useState(0);
        const [timeDifference, setTimeDifference] = useState(0); // timeDifference 상태 추가

        useEffect(() => {
            if (item.complete_code == 0) {
                const updateProgress = () => {
                    // request_date를 한국 표준시로 파싱
                    const requestDateString = item.request_date.split('.')[0] + '+09:00'; // 한국 시간대 추가
                    const requestDate = new Date(requestDateString);

                    // 현재 시간도 한국 표준시로 맞춤
                    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));

                    const diff = (now - requestDate) / 1000 / 60; // minutes
                    setTimeDifference(diff); // 상태 업데이트

                    // 10분 기준으로 최대 99%까지 진행률 계산
                    const progressPercentage = Math.min((diff / 10) * 100, 99);

                    // 로그: 진행률 계산 확인
                    console.log('Progress Percentage:', progressPercentage);

                    setProgress(progressPercentage);
                };

                updateProgress(); // 초기 호출
                const intervalId = setInterval(() => {
                    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
                    const requestDateString = item.request_date.split('.')[0] + '+09:00'; // 한국 시간대 추가
                    const requestDate = new Date(requestDateString);
                    const diff = (now - requestDate) / 1000 / 60; // minutes
                    console.log('diff:', diff);

                    if (diff < 15) {
                        updateProgress(); // 10초마다 업데이트
                        console.log('Progress updated');
                    } else {
                        // diff가 15분 이상이면 인터벌 클리어
                        clearInterval(intervalId);
                    }
                }, 10000); // 10초마다 업데이트

                return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 클리어
            }
        }, [item.request_date, item.complete_code]);

        return (
            <tr>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{formatDate(item.request_date)}</td>

                {/* 채널ID */}
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {dataCollectedTextReplace(item.service_code, "service_code", "")}
                </td>

                {/* 수집/분석파일명 */}
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {item.product_name}
                </td>



                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.request_cnt)}</td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {item.complete_code == 0 ? (
                        timeDifference >= 15 ? (
                            <div style={{ color: 'red' }}>❌실패</div>
                        ) : (
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                backgroundColor: '#b3afaf',
                                height: '20px', // 바 높이 조절
                                borderRadius: '5px', // 모서리 둥글게
                                overflow: 'hidden' // 진행 바가 부모 컨테이너를 넘지 않도록
                            }}>
                                <div style={{
                                    width: `${progress}%`, // 진행률에 따른 너비
                                    height: '100%',
                                    backgroundColor: '#76c7c0',
                                    position: 'absolute', // 절대 위치
                                    left: 0,
                                    top: 0,
                                }}></div>
                                <div style={{
                                    position: 'absolute', // 전체 바의 중앙에 고정
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)', // 중앙 정렬
                                    color: 'white', // 텍스트 색상
                                    // fontWeight: 'bold',
                                    fontSize: '14px' // 글자 크기 조절
                                }}>
                                    {Math.round(progress)}%
                                </div>
                            </div>
                        )
                    ) : (
                        item.service_code.includes('cr') ? "✅수집완료" : "✅분석완료"

                    )}
                </td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {handleLink(item)}
                </td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    <button
                        style={{
                            backgroundColor: 'white',
                            border: 'None',
                            cursor: 'pointer',
                            color: 'blue',
                            padding: '5px'
                        }}
                        onClick={() => { downloadFile(profileID, item.complete_code, item.product_name, 
                                                     item.service_code, item.product_code,
                                                     item.request_cnt, item.unix_time) }}
                    >Download
                    </button>
                </td>
            </tr>
        );
    };

    // const DataTable = ({ dataCollected, dataCollectHeader, dataCollectHeaderWidths, profileID, downloadFile }) => {
    //     // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
    //     const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));

    const DataTable = ({ dataCollected = [], dataCollectHeader, dataCollectHeaderWidths, profileID, downloadFile }) => {
        // dataCollected가 undefined일 경우 빈 배열로 설정하여 sort 에러 방지
        const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));

        return (
            <div>
                {sortedData.length > 0 ? (
                    <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ border: '0.5px solid black' }}>
                                {dataCollectHeader.map((header, index) => (
                                    <th key={header} style={{ width: `${dataCollectHeaderWidths[index]}px`, border: '0.5px solid black', backgroundColor: 'lightgrey', padding: '5px' }}>
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <TableRow key={index} item={item} downloadFile={downloadFile} />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '200px',
                            border: '1px solid black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            fontSize: '1.5em',
                            borderRadius: '10px',
                            padding: '3px',
                            color: 'grey',
                            gap: '10px'
                        }}
                    >
                        <div>👀 데이터워너원 이용 이력이 없어요</div>
                        <Button
                            size="sm"
                            style={{ backgroundColor: mainColor }}
                            onClick={() => { history.push('/') }}
                        >
                            Data 수집/분석하러 가기
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const showDataCollectList = () => {
        // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
        // const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
        const sortedData = (dataCollected || []).sort((a, b) => new Date(b.request_date) - new Date(a.request_date));

        return (
            <div>
                <DataTable
                    dataCollected={sortedData}
                    dataCollectHeader={dataCollectHeader}
                    dataCollectHeaderWidths={dataCollectHeaderWidths}
                    profileID={profileID}
                    downloadFile={downloadFile}
                />
            </div>
        );
    };

    const showPaymentList = () => {
        // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
        const sortedPaymentList = (paymentList || []).sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));
        return (
            <div>
                {sortedPaymentList.length > 0
                    ? // 이력 있는 경우
                    <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ border: '0.5px solid black' }}>
                                {paymentHeader.map((header, index) => (
                                    <th key={header} style={{ width: `${paymentListHeaderWidths[index]}px`, border: '0.5px solid black', backgroundColor: 'lightgrey', padding: '5px' }}>
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPaymentList.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{formatDate(item.payment_date)}</td>
                                    {/* <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.profile_id}</td> */}
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.transaction_no}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{dataCollectedTextReplace(item.service_code, "상품종류", "")}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.product_name}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.request_cnt)}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.payment_amount)}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                                        <a href={dataCollectedTextReplace(item.product_code, "링크", item.service_code)} target='_blank' onClick={clickLink} style={{ color: 'blue', textDecoration: 'underline', padding: '5px', display: 'inline-block'
                                         }}>링크</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    : // 이력 없는 경우

                    <div
                        style={{
                            width: '100%',
                            height: '200px',
                            border: '1px solid black',
                            display: 'flex',
                            flexDirection: 'column', // 수직 배치
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            fontSize: '1.5em',
                            borderRadius: '10px', // 둥근 모서리 추가
                            padding: '3px', // 내부 여백 추가
                            color: 'grey',
                            gap: '10px' // 요소 간의 간격 추가
                        }}
                    >
                        <div>💸 결제 이력이 없어요</div>
                    </div>


                }
            </div>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h1>마이 페이지</h1>
                <br />
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'grey',
                        cursor: 'pointer',
                        fontSize: '16px',
                        padding: '0',
                        textDecoration: 'underline',
                    }}
                    onClick={handleLogout}
                >
                    로그아웃
                </button>
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'red',
                        cursor: 'pointer',
                        fontSize: '16px',
                        padding: '0',
                        textDecoration: 'underline',
                    }}
                    onClick={handleDeleteAccount}
                >
                    회원탈퇴
                </button>
            </div>

            <br /><br />

            <div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                    <h2>👨‍💻 Data 수집/분석 이력</h2>
                    <InfoTooltip text="이력이 정상 조회 안 될 경우 새로고침 해보세요!" />
                </div>
                <br />
                {showDataCollectList()}

            </div>

            <br />

            <div>
                <h2>💰 결제내역</h2>
                <br />
                {showPaymentList()}
            </div>
        </div>
    );
};

export default Mypage;
